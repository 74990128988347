<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="teachersTable"
        striped
        :items="teachers"
        responsive
        :fields="tableFields"
        primary-key="id"
        show-empty
        :empty-text="$t('page.students.search.labels.no-items')"
      >
        <template #cell(name)="data">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <store-image
              v-if="data.item.image_id"
              :store-id="data.item.image_id"
              wrapper-class="student-avatar"
            />
            <b-avatar
              v-else
              size="50px"
            />
            <span class="d-block ml-2">{{ data.value }}</span>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >

            <b-pagination
              v-if="meta"
              v-model="meta.currentPage"
              :total-rows="meta.totalCount"
              :per-page="meta.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="page => $emit('page', page)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BCol, BRow, BAvatar,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'

export default {
  components: {
    StoreImage,
    BCard,
    BTable,
    BPagination,
    BCol,
    BRow,
    BAvatar,
  },
  data: () => ({
    teachers: [],
    meta: false,
  }),
  computed: {
    tableFields() {
      return [
        {
          key: 'name',
          label: this.$t('page.teachers.table.labels.name'),
          sortable: false,
          formatter: (value, key, teacher) => {
            const { usersTeachers } = teacher
            if (usersTeachers) {
              return `${usersTeachers.name} ${usersTeachers.surname} ${usersTeachers.patronymic}`
            }
            return teacher.username
          },
        },
        {
          key: 'skype',
          label: this.$t('page.teachers.table.labels.skype'),
          sortable: false,
        },
        {
          key: 'phone',
          label: this.$t('page.teachers.table.labels.phone'),
          sortable: false,
        },
        {
          key: 'phone_additional',
          label: this.$t('page.teachers.table.labels.phone_additional'),
          sortable: false,
        },
      ]
    },
    defaultMeta() {
      return {
        currentPage: 1,
        pageCount: 0,
        perPage: 16,
        totalCount: 0,
      }
    },
  },
  async created() {
    await this.getTeachers()
  },
  methods: {
    async getTeachers(page = null) {
      const { items, _meta } = await this.$http.get(`/user/current-student-teacher-index?limit=${this.meta.perPage || this.defaultMeta.perPage}&page=${page || this.meta.currentPage || this.defaultMeta.currentPage}&expand=usersTeachers`)
      this.meta = items.length ? _meta : false
      this.teachers = items
    },
  },
}
</script>
